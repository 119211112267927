<template>
  <div>
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input
              v-decorator="[
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="数量">
            <a-input
              v-decorator="[
                'number',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="单位">
            <a-input
              v-decorator="[
                'unit',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button htmlType="submit" type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { add } from "@/api/risk/riskMaterial";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  methods: {
    cancel() {
      this.$router.go(-1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          add({ ...values }).then(() => {
            this.cancel();
          });
        }
      });
    },
  },
};
</script>
